import React from "react";
import { useSnackbar } from "notistack";
import { FormHandles, SubmitHandler } from "@unform/core";
import { NovoGrupoService } from "@/services/endpoints/novo_grupo";

export interface INewGroupsContextProps {
  groups: any[];
  handleDelete: (id: number) => void;
  handleSubmit: SubmitHandler;
  openCreateModal: boolean;
  setDeleteGroupsId: React.Dispatch<React.SetStateAction<number>>;
  deleteGroupsId: number;
  setOpenCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
  formRef: React.RefObject<FormHandles>;
  setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  openDeleteModal: boolean;
}

export const NewGroupsContext = React.createContext({} as INewGroupsContextProps);

interface IProps {
  children: React.ReactNode;
}

export const NewGroupsContextProvider: React.FC<IProps> = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { createGroups, listGroups, deleteGroups } =   new NovoGrupoService();
  const formRef = React.useRef<FormHandles>(null);
  const [deleteGroupsId, setDeleteGroupsId] = React.useState(0);
  const [openCreateModal, setOpenCreateModal] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [groups, setGroups] = React.useState<any[]>([]);

  const handleSubmit: SubmitHandler<any> = (data) => {
    if (!data.name) {
      enqueueSnackbar("Nome do novo grupo é obrigatório.", {
        variant: "error",
      });
      return;
    }

    createGroups(data).then((res) => {
      if (Object.keys(res.data)[0] === "errors") {
        Object.entries(res.data.errors).forEach(
          ([key, value]: [string, any]) => {
            enqueueSnackbar(`${value[0] as string}`, { variant: "error" });
          }
        );
        return;
      }

      enqueueSnackbar("Novo grupo criado com sucesso.", {
        variant: "success",
      });
      setOpenCreateModal(!openCreateModal);
      listGroups().then((res) => {
        setGroups(res.data.data);
      });
    });
  };

  const handleDelete = (id: number) => {
    deleteGroups(id).then(() => {
      listGroups().then((res) => {
        setGroups(res.data.data);
        setOpenDeleteModal(!openDeleteModal);
      });
    });
  };

  React.useEffect(() => {
    if (localStorage.getItem("JWT_USER"))
    listGroups().then((res) => {
      setGroups(res.data.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    groups,
    formRef,
    handleSubmit,
    handleDelete,
    setDeleteGroupsId,
    openDeleteModal,
    deleteGroupsId,
    setOpenDeleteModal,

    openCreateModal,
    setOpenCreateModal,
  } as INewGroupsContextProps;

  return (
    <NewGroupsContext.Provider value={value}>{children}</NewGroupsContext.Provider>
  );
};
