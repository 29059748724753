import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { FormHandles, SubmitHandler } from "@unform/core";
import { NewCategoryService } from "@/services/endpoints/category";
import { NewTypeService } from "@/services/endpoints/tipo";
import { NewStatesService } from "@/services/endpoints/estados";
import { NewCustomerTypeService } from "@/services/endpoints/tipo_de_clientes";
import { NewCurrencyTypeService } from "@/services/endpoints/moedas";
import { NewCentralMethodologiesService } from "@/services/endpoints/metodologia_centrais";

export interface INewCategoryContextProps {
  category: any[];
  type: any[];
  statesData:any[];
  customerTypeData:any[];
  currencyTypeData:any[];
  centralMethodologies:any[];
  handleDelete: (id: number) => void;
  handleEdit: (data: any) => void;
  handleSubmit: (data: any) => void;
  openCreateModal: boolean;
  setDeleteCategoryId: React.Dispatch<React.SetStateAction<number>>;
  deleteCategoryId: number;
  setOpenCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
  formRef: React.RefObject<FormHandles>;
  setOpenEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  openEditModal: boolean;
  setEditCategoria: React.Dispatch<React.SetStateAction<any>>;
  editCategoria: any;
  setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  openDeleteModal: boolean;
  handleGetMyProjects: any
  handleProjetos: any;
  setCategory:any
}

export const NewCategoryContext = React.createContext(
  {} as INewCategoryContextProps
);

interface IProps {
  children: React.ReactNode;
}

export const NewCategoryContextProvider: React.FC<IProps> = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { createCategory, listCategory, deleteCategory, editCategory, getMyProjects } =
    new NewCategoryService();
  const { listStates } = new NewStatesService();
  const { listCustomerType } = new NewCustomerTypeService();
  const { listCurrencyType } = new NewCurrencyTypeService();
  const { listCentralMethodologies } = new NewCentralMethodologiesService();
  const { listType } = new NewTypeService();
  const formRef = React.useRef<FormHandles>(null);
  const [deleteCategoryId, setDeleteCategoryId] = React.useState(0);
  const [openCreateModal, setOpenCreateModal] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [editCategoria, setEditCategoria] = React.useState();
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [category, setCategory] = React.useState<any[]>([]);
  const [type, setType] = React.useState<any[]>([]);
  const [statesData, setStatesData] = React.useState<any[]>([]);
  const [customerTypeData, setCustomerTypeData] = React.useState<any[]>([]);
  const [currencyTypeData, setCurrencyTypeData] = React.useState<any[]>([]);
  const [centralMethodologies, setCentralMethodologies] = React.useState<any[]>([]);

  const handleProjetos = () =>{
    listCategory().then((res) => {
      setCategory(res.data.data);
    });
    listType().then((res) => {
      setType(res.data.data)
    });
    listStates().then((res) => {
      setStatesData(res.data.data)
    });
    listCustomerType().then((res) => {
      setCustomerTypeData(res.data.data)
    });
    listCurrencyType().then((res) => {
      setCurrencyTypeData(res.data.data)
    });
    listCentralMethodologies().then((res) => {
      setCentralMethodologies(res.data.data)
    });
  }
  const handleSubmit: any = (data: any) => {
    console.log("Dados do anexo:", data); // Log dos dados recebidos do formulário

    if (!data.name) {
        enqueueSnackbar("Nome do novo projeto é obrigatório.", {
            variant: "error",
        });
        return;
    }

    data.status = 1


    createCategory(data).then((res) => {
      console.log("Resposta da criação da categoria:", res.data); // Log da resposta do backend

      if (Object.keys(res.data)[0] === "errors") {
        Object.entries(res.data.errors).forEach(
          ([key, value]: [string, any]) => {
            enqueueSnackbar(`${value[0] as string}`, { variant: "error" });
          }
        );
        return;
      }

      enqueueSnackbar("Novo projeto criado com sucesso.", {
        variant: "success",
      });
      setOpenCreateModal(!openCreateModal);
      listCategory().then((res) => {
        setCategory(res.data.data);
      });
    });
  };

  const handleDelete = (id: number) => {
    deleteCategory(id).then(() => {
      listCategory().then((res) => {
        setCategory(res.data.data);
        setOpenDeleteModal(!openDeleteModal);
      });
    });
  };

  const handleEdit = async (data: any) => {
    console.log('data da category', data);
    const res = await editCategory(data);
    
    if (res?.status === 200) {
      enqueueSnackbar("Projeto atualizado com sucesso.", {
        variant: "success",
      });
  
      await handleProjetos();
    }
  };


  const handleGetMyProjects = () =>{
    const id = localStorage.getItem("USER_LOG_ID")

    getMyProjects(id).then((data) =>{
      return data
    }).catch((e) =>{

    })
  }

  React.useEffect(() => {
    if (localStorage.getItem("JWT_USER"))
      listCategory().then((res) => {
        setCategory(res.data.data);
      });
      listType().then((res) => {
        setType(res.data.data)
      });
      listStates().then((res) => {
        setStatesData(res.data.data)
      });
      listCustomerType().then((res) => {
        setCustomerTypeData(res.data.data)
      });
      listCurrencyType().then((res) => {
        setCurrencyTypeData(res.data.data)
      });
      listCentralMethodologies().then((res) => {
        setCentralMethodologies(res.data.data)
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    category,
    type,
    statesData,
    customerTypeData,
    currencyTypeData,
    centralMethodologies,
    formRef,
    handleSubmit,
    handleEdit,
    handleDelete,
    setDeleteCategoryId,
    openDeleteModal,
    deleteCategoryId,
    setOpenDeleteModal,
    openEditModal,
    setOpenEditModal,
    setEditCategoria,
    editCategoria,
    openCreateModal,
    setOpenCreateModal,
    handleGetMyProjects,
    handleProjetos,
    setCategory
  } as INewCategoryContextProps;

  return (
    <NewCategoryContext.Provider value={value}>
      {children}
    </NewCategoryContext.Provider>
  );
};
