import { AxiosPromise } from "axios";
import { axiosInstance } from "@/services/configs/axios";

export class NovoUsuarioService {
  listGroups = (): AxiosPromise<any> =>
    axiosInstance.get("groups");
  createUser = (data: any): AxiosPromise<any> =>
    axiosInstance.post('register', data);
  listUser = (): AxiosPromise => axiosInstance.get('user');
  deleteUser = (id: number): AxiosPromise => axiosInstance.delete('users/delete/' + id);
  changePassword = (data: any, id: number): AxiosPromise => axiosInstance.post(`user/change_password/${id}`, data)
  searchByName = (name:string): AxiosPromise => axiosInstance.get(`user/search?name=${name}`)
  bloqueio = (data: any): AxiosPromise => axiosInstance.put(`bloqueios`, data)
  listBloqueios = (id: any, year: any): AxiosPromise => axiosInstance.get(`/bloqueios/${id}?year=${year}`)
  
  liberarOrBlockGeral = (data: any): AxiosPromise => axiosInstance.post(`/bloqueios/geral`,data)
  liberarOrBlockByUser = (data: any): AxiosPromise => axiosInstance.post(`/bloqueios/user`,data)
  desativarUser = (data: any): AxiosPromise => axiosInstance.post(`/user/desativar/user`,data)
  updatePassword = (data: any, id: number): AxiosPromise => axiosInstance.put(`/user/updatePassword/${id}`, data)
  
}
