export function cpfValidator(cpf: string) {
  // Remover caracteres não numéricos
  cpf = cpf.replace(/[^\d]/g, '');

  // Verificar se o CPF tem 11 dígitos
  if (cpf.length !== 11) {
    return false;
  }

  // Verificar dígitos repetidos (isso não é permitido em um CPF válido)
  if (/^(\d)\1+$/.test(cpf)) {
    return false;
  }

  // Calcular o primeiro dígito verificador
  let soma = 0;
  for (let i = 0; i < 9; i++) {
    soma += parseInt(cpf.charAt(i)) * (10 - i);
  }
  let resto = soma % 11;
  let digito1 = resto < 2 ? 0 : 11 - resto;

  // Verificar o primeiro dígito verificador
  if (parseInt(cpf.charAt(9)) !== digito1) {
    return false;
  }

  // Calcular o segundo dígito verificador
  soma = 0;
  for (let i = 0; i < 10; i++) {
    soma += parseInt(cpf.charAt(i)) * (11 - i);
  }
  resto = soma % 11;
  let digito2 = resto < 2 ? 0 : 11 - resto;

  // Verificar o segundo dígito verificador
  if (parseInt(cpf.charAt(10)) !== digito2) {
    return false;
  }

  // CPF válido
  return true;
}