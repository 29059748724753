import { AxiosPromise } from "axios";
import { axiosInstance } from "@/services/configs/axios";

export class NewCentralMethodologiesService {
  listCentralMethodologies = (): AxiosPromise<any> =>
    axiosInstance.get("central_methodologies");

    getCentralMethodologies = (id: number): AxiosPromise<any> =>
    axiosInstance.get(`central_methodologies/${id}`);
  
}
