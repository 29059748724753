import { useMediaQuery, query } from '@/hooks/useMediaQuery';
import React from 'react';

interface SidebarMenuProps {
  openMenu: boolean;
  openPrivateMenu: boolean;
  setOPenPrivateMenu: React.Dispatch<React.SetStateAction<boolean>>
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SidebarMenuContext = React.createContext({} as SidebarMenuProps);

interface IProps {
  children: React.ReactNode;
}

export const SidebarMenuContextProvider: React.FC<IProps> = ({ children }) => {
  const [openMenu, setOpenMenu] = React.useState(false);
  const { isMobile } = useMediaQuery(query);
  const [openPrivateMenu, setOPenPrivateMenu] = React.useState(() => {
    if (isMobile) {
      return false;
    }

    return true;
  });

  const value = {
    openMenu,
    openPrivateMenu,
    setOpenMenu,
    setOPenPrivateMenu,
  } as SidebarMenuProps;

  return (
    <SidebarMenuContext.Provider value={value}>{children}</SidebarMenuContext.Provider>
  );
};