import React from "react";
import { useSnackbar } from "notistack";
import { FormHandles, SubmitHandler } from "@unform/core";
import { NewTypeService } from "@/services/endpoints/tipo";

export interface INewTypeContextProps {
  type: any[];
  handleDelete: (id: number) => void;
  handleEdit: (data: any) => void;
  handleSubmit: SubmitHandler;
  openCreateModal: boolean;
  setDeleteTypeId: React.Dispatch<React.SetStateAction<number>>;
  deleteTypeId: number;
  setOpenCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
  formRef: React.RefObject<FormHandles>;
  setOpenEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  openEditModal: boolean;
  setEditTipo: React.Dispatch<React.SetStateAction<any>>;
  editTipo: any;
  setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  openDeleteModal: boolean;
}

export const NewTypeContext = React.createContext({} as INewTypeContextProps);

interface IProps {
  children: React.ReactNode;
}

export const NewTypeContextProvider: React.FC<IProps> = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { createType, listType, deleteType, editType } = new NewTypeService();
  const formRef = React.useRef<FormHandles>(null);
  const [deleteTypeId, setDeleteTypeId] = React.useState(0);
  const [openCreateModal, setOpenCreateModal] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [editTipo, setEditTipo] = React.useState();
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [type, setType] = React.useState<any[]>([]);

  const handleSubmit: SubmitHandler<any> = (data) => {
      
   

    data.id_type = 1; //default
    data.status = 1; //default
    data.mandatory_fields = data.externo == "true" ? 1 : 0; //default
    console.log(data,'aw');

    if (!data.description) {
      enqueueSnackbar("Nome do novo tipo é obrigatório.", {
        variant: "error",
      });
      return;
    }
    createType(data).then((res) => {
      if (Object.keys(res.data)[0] === "errors") {
        Object.entries(res.data.errors).forEach(
          ([key, value]: [string, any]) => {
            enqueueSnackbar(`${value[0] as string}`, { variant: "error" });
          }
        );
        return;
      }

      enqueueSnackbar("Novo tipo criado com sucesso.", {
        variant: "success",
      });
      setOpenCreateModal(!openCreateModal);
      listType().then((res) => {
        setType(res.data.data);
      });
    });
  };

  const handleDelete = (id: number) => {
    deleteType(id).then(() => {
      listType().then((res) => {
        setType(res.data.data);
        setOpenDeleteModal(!openDeleteModal);
      });
    });
  };

  const handleEdit = (data: any) => {
    editType(data).then((res) => {
      if (res?.status === 200) {
        enqueueSnackbar("Tipo atualizado com sucesso.", {
          variant: "success",
        });
      }
    });
  };

  React.useEffect(() => {
    if (localStorage.getItem("JWT_USER"))
      listType().then((res) => {
        setType(res.data.data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    type,
    formRef,
    handleSubmit,
    handleEdit,
    handleDelete,
    setDeleteTypeId,
    openDeleteModal,
    deleteTypeId,
    setOpenDeleteModal,
    openEditModal,
    setOpenEditModal,
    setEditTipo,
    editTipo,
    openCreateModal,
    setOpenCreateModal,
  } as INewTypeContextProps;

  return (
    <NewTypeContext.Provider value={value}>{children}</NewTypeContext.Provider>
  );
};
