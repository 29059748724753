import { AxiosPromise } from "axios";
import { axiosInstance } from "@/services/configs/axios";

export class NewCurrencyTypeService {
  listCurrencyType = (): AxiosPromise<any> =>
    axiosInstance.get("currency_types");

    getCurrencyType = (id: number): AxiosPromise<any> =>
    axiosInstance.get(`currency_types/${id}`);
  
}
