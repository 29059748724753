import VLibras from 'vlibras-nextjs';
import type { AppProps } from 'next/app';
import { MiddleContextProvider } from '@/services/providers';

export default function App({ Component, pageProps }: AppProps) {
  return (
    <MiddleContextProvider>
      {process.env.NEXT_PUBLIC_V_LIBRAS === "dev" && <VLibras forceOnload />}
      <Component {...pageProps} />
    </MiddleContextProvider>
  );
}
