import { AxiosPromise } from "axios";
import { axiosInstance } from "@/services/configs/axios";

export class NewStatesService {
  listStates = (): AxiosPromise<any> =>
    axiosInstance.get("states");

    getStates = (id: number): AxiosPromise<any> =>
    axiosInstance.get(`states/${id}`);
  
}
