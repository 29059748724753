import axios from 'axios';

export const axiosLoginInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_URL_LOGIN,
  headers: {
    'Content-Type': 'application/json',
  }
});

export const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_URL_BASE,
  headers: {
    'Content-Type': 'application/json',
  }
});


axiosInstance.interceptors.request.use(async (config: any) => {
  const token = JSON.parse(localStorage.getItem("JWT_USER") as string);

  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const NewCategoryService = {
  listCategory: () => axiosInstance.get("categorys_coast"),
  createCategory: (data:any) => {
    const formData = new FormData();
   
    data.attachment.forEach((file:any, index:any) => {
      formData.append(`attachment[${index}]`, file.name);
    });

    return axiosInstance.post("categorys_coast", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
 
};