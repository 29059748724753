import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { SubmitHandler, FormHandles } from "@unform/core";
import { LoginService } from "@/services/endpoints/login";
import { emailValidator } from "@/utils/emailValidator";
import { useSnackbar } from "notistack";
import { rememberMeStore } from "@/services/zustand/rememberme";

type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

export interface IAuthContextProps {
  errorMessage: string;
  openRegister: boolean;
  authenticated: boolean;
  handleLogin: (email:any,password: any) => void;
  openDrawerLogin: boolean;
  handleLogout: () => void;
  openForgotPassword: boolean;
  setAuthenticated: SetState<boolean>;
  setOpenRegister: SetState<boolean>;
  formRef: React.RefObject<FormHandles>;
  setOPenForgotPassword: SetState<boolean>;
  user: any;
}

export const AuthContext = React.createContext({} as IAuthContextProps);

interface IProps {
  children: React.ReactNode;
}

export const AuthContextProvider: React.FC<IProps> = ({ children }) => {
  const { login } = new LoginService();

  const router = useRouter();
  const formRef = React.useRef<FormHandles>(null);
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [user, setUser] = React.useState<any>();
  const [authenticated, setAuthenticated] = React.useState<boolean>(false);
  const [openForgotPassword, setOPenForgotPassword] =
    React.useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleLogin: SubmitHandler<any> = ( email,password) => {
    if (!password) {
      setErrorMessage("Senha obrigatória");
      formRef.current?.setFieldError("password", "error");
      enqueueSnackbar("Senha obrigatória.", { variant: "error" });

      return;
    }

    const { status, message } = emailValidator(email);

    if (!status) {
      setErrorMessage(message);
      formRef.current?.setFieldError("email", "error");
      enqueueSnackbar("Email é  obrigatório.", { variant: "error" });

      return;
    }

    login({ email, password })
      .then(({ data }) => {
        const { token, user } = data as any;

        if (!token) {
          setErrorMessage("Token indefinido");
          return;
        }
        setUser(user)
        setErrorMessage("");
        setAuthenticated(true);
        localStorage.setItem(
          "USER_GROUP",
          JSON.stringify(user?.group?.modules)
        );
        localStorage.setItem(
          "GRUPO",
          JSON.stringify(user?.group)
        );
        localStorage.setItem("USER_LOG_ID", JSON.stringify(user?.id));
        localStorage.setItem("USER_NAME", JSON.stringify(user?.name));
        localStorage.setItem("EXPIRATION", JSON.stringify(Date.now() + 3600 * 1000));
        router.push("/");
        // rememberMeStore.setState({ email, rememberMe: remember });

        localStorage.setItem("JWT_USER", JSON.stringify(token));
      })
      .catch((e) => {
        enqueueSnackbar("Erro ao logar: " + e.response.data.error, {
          variant: "error",
        });
      });
  };

  

  const handleLogout = () => {
    localStorage.removeItem("JWT_USER");
    if (authenticated) {
      router.push("/login");
    }
    setAuthenticated(!authenticated);
  };


  const value = {
    formRef,
    handleLogin,
    handleLogout,
    errorMessage,
    authenticated,
    setAuthenticated,
    openForgotPassword,
    setOPenForgotPassword,
    user
  } as IAuthContextProps;

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
