import { AxiosPromise } from 'axios';
import { axiosLoginInstance } from '@/services/configs/axios';

export interface Data {
  email: string;
  password: string;
}

export interface LoginReturn {
	token: string;
}

export class LoginService {
  login = (data: any): AxiosPromise<LoginReturn> =>
  axiosLoginInstance.post('login', data); 
}
