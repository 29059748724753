// import { useAuth } from '@/hooks/useAuth';
import { useTimes } from '@/hooks/useTime';
import { LancamentosService } from '@/services/endpoints/lancamentos';
import { generateLastTenYear, horaMinutoParaDecimal, obterInformacoesDataAtual, separarDiasEmSemanas } from '@/utils/date';
import { SelectChangeEvent } from '@mui/material';
// import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';

interface SheetProps {
  currentMonth: any;
  setCurrentMonth: any;
  currentsWeek: any;
  years: number[];
  selectedYear: number;
  setSelectedYear: any;
  handleSelectYear: () => any;
  handleSelectMonth: () => any;
  handleGetSheetUser: () => any;
};

export const SheetContext = React.createContext({} as SheetProps);

interface IProps {
  children: React.ReactNode;
}

export const SheetProvider: React.FC<IProps> = ({ children }) => {


  const [currentMonth, setCurrentMonth] = useState<any>()
  const [currentsWeek, setCurrentsWeeks] = useState<any>()
  const [years, setYears] = useState<number[]>([2021, 2020])
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());


  const handleSelectYear = (event: SelectChangeEvent) => {
    setSelectedYear(Number(event.target.value));
  }

  const handleSelectMonth = (event: SelectChangeEvent) => {
    setCurrentMonth(obterInformacoesDataAtual(event.target.value));
  }

  
 
  const value = {
    currentMonth,
    setCurrentMonth,
    currentsWeek,
    years,
    selectedYear,
    setSelectedYear,
    handleSelectYear,
    handleSelectMonth,
  } as SheetProps;



  
  useEffect(() => {
    setCurrentMonth(obterInformacoesDataAtual())
  }, [])

  useEffect(() => {
    setYears(generateLastTenYear(new Date().getFullYear()))
  }, [])

  useEffect(() => {
    setCurrentsWeeks(separarDiasEmSemanas(selectedYear, currentMonth?.mesNumero))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMonth, selectedYear])


  return (
    <SheetContext.Provider value={value}>{children}</SheetContext.Provider>
  );
};