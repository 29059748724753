import { AxiosPromise } from "axios";
import { axiosInstance } from "@/services/configs/axios";

export class NewTypeService {
  listType = (): AxiosPromise<any> =>
    axiosInstance.get("types");
  createType = (data: any): AxiosPromise<any> =>
    axiosInstance.post('types', data);
  editType = (data: any): AxiosPromise => 
    axiosInstance.put(`types/${data.id}`, data);
  deleteType = (id: number): AxiosPromise => 
    axiosInstance.delete('types/' + id);
}
