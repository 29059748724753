import { AxiosPromise } from "axios";
import { axiosInstance } from "@/services/configs/axios";

export class NewActionService {
  listAction = (): AxiosPromise<any> =>
    axiosInstance.get("actions_types");
  createAction = (data: any): AxiosPromise<any> =>
    axiosInstance.post('actions_types', data);
  editAction = (data: any): AxiosPromise => 
    axiosInstance.put(`actions_types/${data.id}`, data);
  deleteAction = (id: number): AxiosPromise => 
    axiosInstance.delete('actions_types/' + id);
}
