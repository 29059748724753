import { createRef } from "react";
import {
  SnackbarAction,
  SnackbarOrigin,
  SnackbarProvider as Provider,
} from "notistack";
import { Button } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

interface IProps {
  children: React.ReactNode;
}

declare module "notistack" {
  interface VariantOverrides {
    reportComplete: true;
  }
}

export function SnackbarProvider({ children }: IProps) {
  const ref = createRef<Provider>();

  const handleClick = (key: any) => () => {
    ref.current?.closeSnackbar(key);
  };

  const iconVariant = {
    error: "",
    success: "",
    warning: "",
    info: "",
  };

  const anchorOrigin = {
    horizontal: "right",
    vertical: "top",
  } as SnackbarOrigin;

  const action = (key: SnackbarAction) => (
    <Button
      sx={{
        minWidth: "24px",
        borderRadius: "50%",
      }}
      color="inherit"
      onClick={handleClick(key)}
    >
      <CancelIcon />
    </Button>
  );

  return (
    <Provider
      ref={ref}
      maxSnack={3}
      action={action}
      preventDuplicate
      iconVariant={iconVariant}
      anchorOrigin={anchorOrigin}
    >
      {children}
    </Provider>
  );
}
