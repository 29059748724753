import { AxiosPromise } from "axios";
import { axiosInstance } from "@/services/configs/axios";

export class NewCustomerTypeService {
  listCustomerType = (): AxiosPromise<any> =>
    axiosInstance.get("customer_types");

    getCustomerType = (id: number): AxiosPromise<any> =>
    axiosInstance.get(`customer_types/${id}`);
  
}
