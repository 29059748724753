import { AxiosPromise } from "axios";
import { axiosInstance } from "@/services/configs/axios";

export class NovoGrupoService {
  listGroups = (): AxiosPromise<any> =>
    axiosInstance.get("groups");
  createGroups = (data: any): AxiosPromise<any> =>
    axiosInstance.post('groups/create', data);
  deleteGroups = (id: number): AxiosPromise => axiosInstance.delete('groups/delete/' + id);
}
