// import { useAuth } from '@/hooks/useAuth';
import { useSheet } from '@/hooks/useSheet';
import { LancamentosService } from '@/services/endpoints/lancamentos';
// import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';

interface SheetProps {
  sheetData: any;
  totals: any;
  disabled: any;
  setTotals: any;
  totalHoras: any;
  handleGetSheetUser: () => any;
  dataToPost: any;
  setDataToPost: any;
  disabledButton: any;
  setDisabledButton: any;
};

export const TimeContext = React.createContext({} as SheetProps);

interface IProps {
  children: React.ReactNode;
}

export const TimeProvider: React.FC<IProps> = ({ children }) => {


  const [disabled, setDisabled] = useState<boolean>(false)
  const [disabledButton, setDisabledButton] = useState<boolean>(false)
  const [totals, setTotals] = useState<any[]>([])
  const [totalHoras, setTotalHoras] = useState<any[]>([])
  const [sheetData, setSheetData] = useState([])
  const [dataToPost, setDataToPost] = React.useState([])

  const { currentMonth, selectedYear } = useSheet()

  const { getHoursSheet } = new LancamentosService()


  const handleGetSheetUser = () => {
    const id = localStorage.getItem("USER_LOG_ID")

    if (currentMonth?.mesNumero && selectedYear && id) {
      getHoursSheet(id, { year: selectedYear, month: currentMonth?.mesNumero }).then((data) => {
        setSheetData(data?.data?.table)
        setDisabled(data?.data?.bloqueado)
        setTotals(data?.data?.total)
        setTotalHoras(data?.data?.totalhoras)
      }).catch((error) => {
        console.log(error);
      })
    }
  }



  useEffect(() => {
    setDataToPost([])
  }, [currentMonth, selectedYear])

  useEffect(() => {
    console.log(dataToPost, 'dataToPost');

  }, [dataToPost])
  const value = {
    sheetData,
    totals,
    totalHoras,
    handleGetSheetUser,
    disabled,
    setTotals,
    setTotalHoras,
    dataToPost,
    setDataToPost,
    disabledButton,
    setDisabledButton
  } as SheetProps;


  useEffect(() => {
    handleGetSheetUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear, currentMonth])






  return (
    <TimeContext.Provider value={value}>{children}</TimeContext.Provider>
  );
};