import React from "react";
import { useSnackbar } from "notistack";
import { FormHandles, SubmitHandler } from "@unform/core";
import { cpfValidator } from "@/utils/cpfValidator";
import { celphoneValidator } from "@/utils/celphoneValidator";
import { emailValidator } from "@/utils/emailValidator";
import { NovoUsuarioService } from "@/services/endpoints/novo_usuario";

export interface INewUserContextProps {
  users: any[];
  groups: any[];
  handleDelete: (id: number) => void;
  handleSubmit: SubmitHandler;
  handleSubmitUpdate: SubmitHandler;
  openCreateModal: boolean;
  openUpdatePasswordModal: boolean;
  setDeleteUserId: React.Dispatch<React.SetStateAction<number>>;
  setUpdatePasswordData: React.Dispatch<React.SetStateAction<any>>;
  deleteUserId: number;
  updatePasswordData: any;
  setOpenCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenUpdatePasswordModal: React.Dispatch<React.SetStateAction<boolean>>;
  formRef: React.RefObject<FormHandles>;
  setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  openDeleteModal: boolean;
  setisDeleteOrUpdate: any;
  isDeleteOrUpdate: any;
}

export const NewUserContext = React.createContext({} as INewUserContextProps);

interface IProps {
  children: React.ReactNode;
}

export const NewUserContextProvider: React.FC<IProps> = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { listGroups, createUser, listUser, deleteUser, desativarUser, updatePassword } =
    new NovoUsuarioService();
  const [users, setUsers] = React.useState<any[]>([]);
  const formRef = React.useRef<FormHandles>(null);
  const [deleteUserId, setDeleteUserId] = React.useState(0);
  const [updatePasswordData, setUpdatePasswordData] = React.useState<any>();
  const [openCreateModal, setOpenCreateModal] = React.useState(false);
  const [openUpdatePasswordModal, setOpenUpdatePasswordModal] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [isDeleteOrUpdate, setisDeleteOrUpdate] = React.useState(0);

  const [groups, setGroups] = React.useState<any[]>([]);

  const handleSubmit: SubmitHandler<any> = (data) => {
    if (!data.name) {
      enqueueSnackbar("Nome do novo usuário é obrigatório.", {
        variant: "error",
      });
      return;
    }

    const cpf = cpfValidator(data.cpf);

    if (!cpf) {
      enqueueSnackbar("CPF inválido.", { variant: "error" });
      return;
    }

    

    if (data.group_id === 0) {
      enqueueSnackbar("Selecione o grupo de usuários", { variant: "error" });
      return;
    }

    const { status, message } = emailValidator(data.email);

    if (!status) {
      enqueueSnackbar(message, { variant: "error" });
      return;
    }

    if (!data.password) {
      enqueueSnackbar("Senha obrigatória.", { variant: "error" });
      return;
    }

    if (!data.password_confirmation) {
      enqueueSnackbar("Confirmação de senha obrigatória.", {
        variant: "error",
      });
      return;
    }

    if (data.password !== data.password_confirmation) {
      enqueueSnackbar("Senhas não são iguais.", { variant: "error" });
      return;
    }

    createUser(data).then((res) => {
      if (Object.keys(res.data)[0] === "errors") {
        Object.entries(res.data.errors).forEach(
          ([key, value]: [string, any]) => {
            enqueueSnackbar(`${value[0] as string}`, { variant: "error" });
          }
        );
        return;
      }

      enqueueSnackbar("Novo usuário criado com sucesso.", {
        variant: "success",
      });
      setOpenCreateModal(!openCreateModal);
      listUser().then((res) => {
        setUsers(res.data.data);
      });
    }).catch((e) =>{
      enqueueSnackbar("Erro ao criar usuário", {
        variant: "error",
      });
    });
  };

  const handleSubmitUpdate: SubmitHandler<any> = (data) => {
    if (!data.password) {
      enqueueSnackbar("Senha obrigatória.", { variant: "error" });
      return;
    }

    if (!data.password_confirmation) {
      enqueueSnackbar("Confirmação de senha obrigatória.", {
        variant: "error",
      });
      return;
    }

    if (data.password !== data.password_confirmation) {
      enqueueSnackbar("Senhas não são iguais.", { variant: "error" });
      return;
    }

    updatePassword(data, updatePasswordData?.id).then((res) => {
      if (Object.keys(res.data)[0] === "errors") {
        Object.entries(res.data.errors).forEach(
          ([key, value]: [string, any]) => {
            enqueueSnackbar(`${value[0] as string}`, { variant: "error" });
          }
        );
        return;
      }

      enqueueSnackbar("Senha alterada com sucesso.", {
        variant: "success",
      });
      setOpenUpdatePasswordModal(!openUpdatePasswordModal);
    }).catch((e: any) =>{
      enqueueSnackbar(`Erro ao alterar a senha: ${e.response.data.message}`, {
        variant: "error",
      });
    });
  };

  const handleDelete = (id: number, status: any) => {
    desativarUser({id, status: isDeleteOrUpdate ==  0 ? 2: 0}).then(() => {
      listUser().then((res) => {
        setUsers(res.data.data);
        setOpenDeleteModal(!openDeleteModal);
      });
    });
  };

  // React.useEffect(() => {
  //   if (localStorage.getItem("JWT_USER"))
  //   listGroups().then((res) => {
  //     setGroups(res.data);
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  React.useEffect(() => {
    if (localStorage.getItem("JWT_USER"))
    listUser().then((res) => {
      setUsers(res.data.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    users,
    groups,
    formRef,
    handleSubmit,
    handleSubmitUpdate,
    handleDelete,
    setDeleteUserId,
    setUpdatePasswordData,
    updatePasswordData,
    openDeleteModal,
    deleteUserId,
    setOpenDeleteModal,
    setisDeleteOrUpdate,
    openCreateModal,
    setOpenCreateModal,
    isDeleteOrUpdate,
    openUpdatePasswordModal,
    setOpenUpdatePasswordModal
  } as INewUserContextProps;

  return (
    <NewUserContext.Provider value={value}>{children}</NewUserContext.Provider>
  );
};
