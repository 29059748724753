import { AxiosPromise } from "axios";
import { axiosInstance } from "@/services/configs/axios";

export class NewCategoryService {
  listCategory = (): AxiosPromise<any> =>
    axiosInstance.get("categorys_coast");
  createCategory = (data: any): AxiosPromise<any> =>
    axiosInstance.post('categorys_coast', data);
  editCategory = (data: any): AxiosPromise =>
    axiosInstance.put(`categorys_coast/${data.id}`, data);
  deleteCategory = (id: number): AxiosPromise =>
    axiosInstance.delete('categorys_coast/' + id);

  getMyProjects = (idUser: any): AxiosPromise<any> =>
    axiosInstance.get(`categorys_coast/${idUser}/projetos`);

  getProject = (idProject: any): AxiosPromise<any> =>
    axiosInstance.get(`categorys_coast/${idProject}`);

  getMyProjectsWithFilter = (idUser: any, filter: any): AxiosPromise<any> =>
    axiosInstance.get(`categorys_coast/${idUser}/projetos/${filter}`);

  postMyProject = (idUser: any, data: any): AxiosPromise<any> =>
    axiosInstance.post(`categorys_coast/${idUser}/projetos`, data);

  deleteMyProject = (id: any): AxiosPromise<any> =>
    axiosInstance.delete(`categorys_coast/${id}/projetos/1`);

  getPagination = (idUser: any, page: any): AxiosPromise<any> =>
    axiosInstance.get(`categorys_coast/${idUser}/projetos?page=${page}`);

  getPaginationWithFilter = (idUser: any, page: any, filter: any): AxiosPromise<any> =>
    axiosInstance.get(`categorys_coast/${idUser}/projetos/${filter}?page=${page}`);

}
