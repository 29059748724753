import React from "react";
import { SidebarMenuContextProvider } from "@/contexts/sidebar";
import { AuthContextProvider } from "@/contexts/auth";
import { NewUserContextProvider } from "@/contexts/new_user";
import { SnackbarProvider } from "./snackbar";
import { SheetProvider } from "@/contexts/sheet";
import { NewGroupsContextProvider } from "@/contexts/new_group";
import { NewCategoryContextProvider } from "@/contexts/category";
import { NewTypeContextProvider } from "@/contexts/tipo";
import { NewActionContextProvider } from "@/contexts/actions";
import { TimeProvider } from "@/contexts/time";

interface IProps {
  children: React.ReactNode;
}

export const MiddleContextProvider: React.FC<IProps> = ({ children }) => {
  return (
    <SnackbarProvider>
      <AuthContextProvider>
        <SidebarMenuContextProvider>
          <NewCategoryContextProvider>
            <SheetProvider>
              <TimeProvider>
              <NewActionContextProvider>
                <NewTypeContextProvider>
                  <NewGroupsContextProvider>
                    <NewUserContextProvider>{children}</NewUserContextProvider>
                  </NewGroupsContextProvider>
                </NewTypeContextProvider>
              </NewActionContextProvider>
              </TimeProvider>
            </SheetProvider>
          </NewCategoryContextProvider>
        </SidebarMenuContextProvider>
      </AuthContextProvider>
    </SnackbarProvider>
  );
};
