import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { FormHandles, SubmitHandler } from "@unform/core";
import { NewActionService } from "@/services/endpoints/action";
import { NewTypeService } from "@/services/endpoints/tipo";

export interface INewActionContextProps {
  actions: any[];
  type: any[];
  handleDelete: (id: number) => void;
  handleEdit: (data: any) => void;
  handleSubmit: SubmitHandler;
  openCreateModal: boolean;
  setDeleteActionId: React.Dispatch<React.SetStateAction<number>>;
  deleteActionId: number;
  setOpenCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
  formRef: React.RefObject<FormHandles>;
  setOpenEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  openEditModal: boolean;
  setEditActions: React.Dispatch<React.SetStateAction<any>>;
  editActions: any;
  setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  openDeleteModal: boolean;
  setDataToPost: any
  dataToPost: any
}

export const NewActionContext = React.createContext({} as INewActionContextProps);

interface IProps {
  children: React.ReactNode;
}

export const NewActionContextProvider: React.FC<IProps> = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { createAction, listAction, deleteAction, editAction } = new NewActionService();
  const { listType } = new NewTypeService();
  const formRef = React.useRef<FormHandles>(null);
  const [deleteActionId, setDeleteActionId] = React.useState(0);
  const [openCreateModal, setOpenCreateModal] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [editActions, setEditActions] = React.useState();
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [actions, setActions] = React.useState<any[]>([]);
  const [type, setType] = React.useState<any[]>([]);

  const handleSubmit: SubmitHandler<any> = (data) => {
    if (openCreateModal) {

      if (data.description.length == 0) {
        enqueueSnackbar("Nome da nova ação é obrigatório.", {
          variant: "error",
        });
        return;
      }
      if (data.id_type == 0) {
        enqueueSnackbar("Tipo  da nova ação é obrigatório.", {
          variant: "error",
        });
        return;
      }

      data.status = 1; //default

      createAction(data).then((res) => {
        if (Object.keys(res.data)[0] === "errors") {
          Object.entries(res.data.errors).forEach(
            ([key, value]: [string, any]) => {
              enqueueSnackbar(`${value[0] as string}`, { variant: "error" });
            }
          );
          return;
        }

        enqueueSnackbar("Novo tipo criado com sucesso.", {
          variant: "success",
        });
        setOpenCreateModal(!openCreateModal);
        listAction().then((res) => {
          setActions(res.data.data);
        });
      });


    }
  };

  const handleDelete = (id: number) => {
    deleteAction(id).then(() => {
      listAction().then((res) => {
        setActions(res.data.data);
        setOpenDeleteModal(!openDeleteModal);
      });
    });
  };

  const handleEdit = (data: any) => {
    data.mandatory_fields = data.externo == "true" ? 1 : 0; //default

    editAction(data).then((res) => {
      if (res?.status === 200) {
        enqueueSnackbar("Tipo atualizado com sucesso.", {
          variant: "success",
        });
      }
      listAction().then((res) => {
        setActions(res.data.data);
      });
    });
  };

  React.useEffect(() => {
    if (localStorage.getItem("JWT_USER"))
      listAction().then((res) => {
        setActions(res.data.data);
      });
    listType().then((res) => {
      setType(res.data.data)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const value = {
    actions,
    type,
    formRef,
    handleSubmit,
    handleEdit,
    handleDelete,
    setDeleteActionId,
    openDeleteModal,
    deleteActionId,
    setOpenDeleteModal,
    openEditModal,
    setOpenEditModal,
    setEditActions,
    editActions,
    openCreateModal,
    setOpenCreateModal,
  } as INewActionContextProps;

  return (
    <NewActionContext.Provider value={value}>{children}</NewActionContext.Provider>
  );
};
