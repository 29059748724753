import { AxiosPromise } from 'axios';
import { axiosInstance } from '@/services/configs/axios';

export interface Data {
  email: string;
  password: string;
}

export interface LoginReturn {
	token: string;
}

interface DateProps {
	year: any;
	month: any;
}
function generateQueryString(params: any) {
  const queryString = Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
  return queryString;
}

export class LancamentosService {
  getHoursSheet = (userId: string, data: DateProps): AxiosPromise<any> =>
  axiosInstance.get(`time_coast_category/hours/${userId}?year=${data.year}&month=${data.month}`); 

  postHours = ( data: any): AxiosPromise<any> =>
  axiosInstance.post(`/time_coast_category`,data); 

  
  updateHour = ( data: any, id: any): AxiosPromise<any> =>
  axiosInstance.put(`/time_coast_category/${id}`,data); 

  getRelatorio = (data: any): AxiosPromise<any> =>
  axiosInstance.get(`time_coast_category/relatorios/user?year=${data.year}&month=${data.month}&idUser=${data.idUser}&projectId=${data.projectId}`); 
}
