export const meses = [
  { nome: "Janeiro", numero: 1 },
  { nome: "Fevereiro", numero: 2 },
  { nome: "Março", numero: 3 },
  { nome: "Abril", numero: 4 },
  { nome: "Maio", numero: 5 },
  { nome: "Junho", numero: 6 },
  { nome: "Julho", numero: 7 },
  { nome: "Agosto", numero: 8 },
  { nome: "Setembro", numero: 9 },
  { nome: "Outubro", numero: 10 },
  { nome: "Novembro", numero: 11 },
  { nome: "Dezembro", numero: 12 }
];

interface Iprops{
    data: any, // Formato 'AAAA-MM-DD'
    diaNumero: any,
    diaNome: any,
    mesNumero: any,
    mesNome: any,
    ano: any,
    timestamp: any
}
export function separarDiasEmSemanas(ano: any, mes: any) {
  var data = new Date(ano, mes - 1, 1); // Meses são baseados em zero
  var semanas = [];
  var semana = [];

  while (data.getMonth() === mes - 1) {
    if (data.getDay() >= 1 && data.getDay() <= 5) {
      var dia = {
        dia: data.getDate(),
        nomeDoDia: obterNomeDoDia(data.getDay()),
        mes: data.getMonth() + 1
      };

      semana.push(dia);
    }

    if (data.getDay() === 5) { // Se for sexta-feira, concluímos a semana
      semanas.push(semana);
      semana = [];
    }

    data.setDate(data.getDate() + 1);
  }

  // Adicione a última semana, se houver dias úteis restantes
  if (semana.length > 0) {
    semanas.push(semana);
  }

  return semanas;
}


export function obterInformacoesDataAtual(mesParametro?: any): Iprops {
  try {
    let data;
    
    if (mesParametro) {
      const hoje = new Date();
      const anoAtual = hoje.getFullYear();
      data = new Date(anoAtual, mesParametro - 1, 1); // Subtrai 1 do mês para ajustar a indexação (janeiro = 0)
    } else {
      data = new Date();
    }

    const diaNumero = data.getDate();
    const diaNome = obterNomeDoDia(data.getDay());
    const mesNumero = data.getMonth() + 1; // Os meses em JavaScript são baseados em zero (janeiro = 0)
    const mesNome = obterNomeMes(data.getMonth());
    const ano = data.getFullYear();
    const timestamp = Math.floor(data.getTime() / 1000); // Converte milissegundos em segundos

    return {
      data: data.toISOString().split('T')[0], // Formato 'AAAA-MM-DD'
      diaNumero,
      diaNome,
      mesNumero,
      mesNome,
      ano,
      timestamp
    };
  } catch (error) {
    return {
      data:  null, // Formato 'AAAA-MM-DD'
      diaNumero: null,
      diaNome: null,
      mesNumero: null,
      mesNome: null,
      ano: null,
      timestamp: null,
    };
  }
}


function obterNomeDoDia(numeroDoDia: number) {
  var nomesDosDias = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"];
  return nomesDosDias[numeroDoDia];
}

export function obterNomeMes(mes: any) {
  const nomesMeses = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
  return nomesMeses[mes];
}


export function generateLastTenYear(year: number){
  const years = []

  for(let i = year ; i >= year - 10; i--){
    years.push(i)
  }

  return years;
}

export function horaMinutoParaDecimal(horaMinuto: string) {
  const partes = horaMinuto.split(":"); // Divide a string nos dois pontos
  if (partes.length !== 2) {
    throw new Error("Formato de entrada inválido. Use o formato HH:MM.");
  }

  const horas = parseInt(partes[0], 10); // Converte a parte das horas em um número inteiro
  const minutos = parseInt(partes[1], 10); // Converte a parte dos minutos em um número inteiro

  if (isNaN(horas) || isNaN(minutos) || horas < 0 || minutos < 0 || minutos >= 60) {
    throw new Error("Hora ou minutos inválidos.");
  }

  // Calcula o número decimal representando as horas e minutos
  const decimal = horas + minutos / 60;

  return decimal;
}


export function decimalParaHoraMinuto(decimal:any) {
  if (!isNaN(decimal)) {
    // Extrai a parte inteira (horas) e a parte decimal (minutos)
    const horas = Math.floor(decimal);
    const minutos = Math.round((decimal - horas) * 60);

    // Formata as horas e minutos com dois dígitos
    const horasFormatadas = horas.toString().padStart(2, '0');
    const minutosFormatados = minutos.toString().padStart(2, '0');

    // Retorna a hora no formato HH:MM
    return `${horasFormatadas}:${minutosFormatados}`;
  }
  return null; // Retorna null se o valor não for um número
}
